import { JobsResponse } from "../partners/OfflineEval/models/JobsResponse";
import type { MetricsChangeLog } from "../partners/OfflineEval/models/MetricsChangeLog";
import { MetricsChangeLogList } from "../partners/OfflineEval/models/MetricsChangeLog";

import type {
  AddJobPermissionRequestData,
  BingJobFileDownloadLinkRequestData,
  CallAvalonRequestData,
  CancelJobRouterRequestData,
  CheckOnlineQuerySetContentRouterRequestData,
  CheckOnlineQuerySetWarningRouterRequestData,
  CreateDetailsByJobIdRouterRequestData,
  CreateJobRouterRequestData,
  CreateScheduleJobRequestData,
  EditConfigRouterRequestData,
  ErrorNodeForFailedJobRouterRequestData,
  ExperimentListRequestData,
  ExperimentRequestData,
  ExperimentScorecardListRequestData,
  FeatureIterationRequestData,
  GetConfigSnapshotRouterRequestData,
  GetDynamicPermissionRouterRequestData,
  GetHeronJobOutputLinkRequestData,
  GetJobPermissionRequestData,
  GetOPGRAIResultRequestData,
  GetQuerySetContentRouterRequestData,
  GetSGMembersRequestData,
  GPTListRouterParser,
  JobPerfMetricRequestData,
  JobRouterRequestData,
  JobsRouterRequestData,
  ListConfigSnapshotRouterRequestData,
  LMChecklistAssertionsRouterRequestData,
  LMChecklistDetailsByJobIdRouterRequestData,
  LogRequestData,
  PreSubmitJobRouterRequestData,
  ProductCreateRequestData,
  ProductDeleteRequestData,
  ProductUpdateRequestData,
  QueryMChatAllMetricsRouterParserRequestData,
  QueryMChatMetricsRouterParserRequestData,
  QuerySvalueRouterRequestData,
  RemoveJobPermissionRequestData,
  RerunJobRouterParserRequestData,
  SearchScheduleJobData,
  SearchSGRouterRequestData,
  SetIncidentsRouterRequestData,
  SetTokenRouterRequestData,
  ShareByPassedUsersRequestData,
  UpdateJobPriorityRequestData,
  UpdateRefreshTokenByCodeRouterRequestData,
  UpdateScheduleJobRequestData,
  UtteranceConversationIdRouterRequestData,
  ValidateADOLinkRequestData,
  VerifyTokenRouterRequestData,
} from "sydneyeval-shared";
import {
  anyObject,
  array,
  GPTAgentListResponse,
  IncidentList,
  MetricJudgementRecord,
  none,
  num,
  optional,
  parseJsonStrWithDefault,
  PrivacyFilterTemplateContentArray,
  Product,
  ScheduledJobResponse,
  str,
  UserProfileResponse,
} from "sydneyeval-shared";
import { AppConfigurationSnapshotsResponse } from "../models/AppConfigurationSnapshot";
import { AvalonResponse } from "../models/Avalon";
import { Consent } from "../models/Consent";
import { HeronJobOutputLinkResponse } from "../models/HeronJobOutputLinkResponse";
import { Permission } from "../models/Permission";
import { QuerySetFile } from "../models/QuerySetFile";
import {
  SearchUserListResponse,
  SearchUserResponse,
  SubstrateSingleUserInfor,
} from "../models/SearchUserByIdResponse";
import { VirtualTenant } from "../models/VirtualTenant";
import { CheckOnlineQuerySetContentResponse } from "../partners/OfflineEval/models/CheckOnlineQuerySetContentResponse";
import { ErrorMessageResponse } from "../partners/OfflineEval/models/ErrorMessageResponse";
import { ErrorNodeResponse } from "../partners/OfflineEval/models/ErrorNodeResponse";
import { GeneralMetricsResponse } from "../partners/OfflineEval/models/GeneralMetricsResponse";
import { GetUserManagerResponse } from "../partners/OfflineEval/models/GetUserManagerResponse";
import {
  CreatedJobResponse,
  UpdateJobPriorityResponse,
  ValidateADOLinkResponse,
} from "../partners/OfflineEval/models/Job";
import { JobOutputResponse } from "../partners/OfflineEval/models/JobOutputResponse";
import { JobSBSExpResponse } from "../partners/OfflineEval/models/JobSBSExpResponse";
import { JobTemplatesResponse } from "../partners/OfflineEval/models/JobTemplatesResponse";
import { KustoQueryResultResponse } from "../partners/OfflineEval/models/KustoPerfResult";
import { LMChecklistAssertionsResponse } from "../partners/OfflineEval/models/LMChecklistAssertionsResponse";
import { LMChecklistDetailsResponse } from "../partners/OfflineEval/models/LMChecklistDetailsResponse";
import { LMChecklistIcmsResponse } from "../partners/OfflineEval/models/LMChecklistIcmsResponse";
import { OPGRAIResult } from "../partners/OfflineEval/models/OPGRAIResult";
import { UpdateTokenByCodeResponse } from "../partners/OfflineEval/models/UpdateTokenByCodeResponse";
import { UtteranceConversationId } from "../partners/OfflineEval/models/UtteranceConversationId";
import {
  MChatMetricsByHashResponse,
  MChatMetricsByMetricsResponse,
  MChatSValueResponse,
} from "../partners/OfflineEval/models/UtteranceResponse";
import { VerifyTokenResponse } from "../partners/OfflineEval/models/VerifyTokenResponse";
import {
  ExperimentScorecardsResponse,
  ExperimentsResponse,
  FeatureEvalExperiment,
  ScorecardMetricsResponse,
} from "../partners/ShadowAB/models/Experiment";
import {
  cleanLocalAvalonAccessTokenOnError,
  cleanLocalHeronAccessTokenOnError,
  cleanLocalSubstrateTokenOnError,
  getAvalonAccessToken,
} from "./accessTokenHelper";
import { createRequest } from "./createRequest";
import { getBasicHeader, getBasicHeaderWithoutAuth } from "./headerHelper";

const convertToQueryString = (query: Record<string, string>) => {
  return Object.entries(query)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join("&");
};

export interface IUploadConsumerJobParams {
  JobName: string;
  RequestId: string;
  CreatedBy: string;
  CreatorSmtpAddress: string;
  JsonConfig: string;
}

export interface IDeleteJobParams {
  CreatorSmtpAddress: string;
}

export type JobStatusType =
  | "Running"
  | "Created"
  | "Submitted"
  | "Completed"
  | "Failed"
  | "SubmitFailed"
  | "Cancelled"
  | "Deleted";

interface IDeleteTokenParams {
  SecretName: string;
}

interface ISearchUserByIdParams {
  SubstrateToken: string;
  UserIds: string[];
  CallerFileNameLine: string;
  ClientRequestId: string;
  ProcessName: string;
}
interface IGetMetricsByJobIdParams {
  JobId: string;
}

interface IUploadQuerySetParams {
  File: File;
  FileName: string;
  FileType: "Bing" | "MChat";
}

interface IUploadFolderParams {
  remoteFolderName: string;
  folder: File[];
  localFolderName: string;
}

interface IIngestSyntheticDataParams {
  ContainerName: string;
  BlobName: string;
  SubmittedBy: string;
}

interface ISetConsentParams {
  Consent: Consent;
  User: string;
}

interface IGetConsentParams {
  User: string;
}

interface ISetMetricsChangeParams {
  MetricsChange: MetricsChangeLog[] | undefined;
}

interface IGetErrorMessageForFailedJobParams {
  readonly JobId: string;
  readonly JobTemplateType: string;
}

interface IGetKeyVaultSecretByKeyParams {
  readonly SecretName: string;
}

interface ISetKeyVaultSecretByKeyParams {
  readonly SecretName: string;
  readonly SecretValue: string;
}

export const getPublicQuerySets = () =>
  createRequest({
    api: "/getquerysets?folder=public",
    typeGuard: array(QuerySetFile),
  });

export const getCustomQuerySets = () =>
  createRequest({
    api: "/getquerysets",
    typeGuard: array(QuerySetFile),
  });

export const getPublicLMChecklistSets = () =>
  createRequest({
    api: "/getquerysets?folder=lmchecklist/public",
    typeGuard: array(QuerySetFile),
  }).then((files) => files.map((file) => file.name));

export const getCustomLMChecklistSets = () =>
  createRequest({
    api: "/getquerysets?folder=lmchecklist",
    typeGuard: array(QuerySetFile),
  }).then((files) => files.map((file) => file.name));

export const getBingUserSets = (type: "Bing" | "CWC") =>
  createRequest({
    api: `/getBingQuerySets?type=${type}`,
    typeGuard: array(QuerySetFile),
  });

export const getQuerySetGenerations = () =>
  createRequest({
    api: "/getQuerySetGenerations",
    typeGuard: array(QuerySetFile),
  });

export const getBingQuerySetGenerations = () =>
  createRequest({
    api: "/getBingQuerySetGenerations",
    typeGuard: array(QuerySetFile),
  });

export const checkQuerySetHeader = (fileName: string) =>
  createRequest({
    api: `/checkQuerySetHeader?filename=${encodeURIComponent(fileName)}`,
    typeGuard: str,
  });

export const checkOnlineQuerySetContent = (
  param: CheckOnlineQuerySetContentRouterRequestData,
) =>
  createRequest({
    api: `/checkOnlineQuerySetContent`,
    requestData: param,
    typeGuard: CheckOnlineQuerySetContentResponse,
  });

export const checkLocalQuerySetContent = async (
  file: File,
  templateName?: string,
) =>
  createRequest({
    api: "/checkLocalQuerySetContent",
    requestData: (() => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("templateName", templateName ?? "");
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const getQuerySetContent = (
  query: GetQuerySetContentRouterRequestData,
) =>
  createRequest({
    api: `/getQuerySetContent?${convertToQueryString(query)}`,
    typeGuard: str,
  });

export const deleteQuerySet = (params: { file: string; source: string }) =>
  createRequest({
    api: `/deleteStorageFile`,
    requestData: params,
    typeGuard: none,
  });

export const getJob = (params: JobRouterRequestData) =>
  createRequest({
    api: `/job`,
    requestData: params,
    typeGuard: JobsResponse,
  });

export const getJobOutput = (jobId: string) =>
  createRequest({
    api: `/getJobOutput?jobId=${encodeURIComponent(jobId)}`,
    typeGuard: JobOutputResponse,
  });

export const getJobOutputFileContent = (fileName: string) =>
  createRequest({
    api: `/getJobOutputFileContent?filename=${encodeURIComponent(fileName)}`,
    typeGuard: str,
  });

export const getHeronJobOutputLink = (
  params: GetHeronJobOutputLinkRequestData,
) =>
  cleanLocalHeronAccessTokenOnError(
    createRequest({
      api: `/getHeronJobOutputLink`,
      requestData: params,
      typeGuard: HeronJobOutputLinkResponse,
    }),
  );

export const getBingAMLFileDownloadLink = (
  query: BingJobFileDownloadLinkRequestData,
) =>
  createRequest({
    api: `/getBingAMLFileDownloadLink?${convertToQueryString(query)}`,
    typeGuard: str,
  });

export const getAzureMLFileContent = (path: string) =>
  createRequest({
    api: `/getAzureMLFileContent`,
    requestData: { AzureMLPath: path },
    typeGuard: str,
  });

export const getJobTemplates = () =>
  createRequest({
    api: "/jobtemplates",
    requestData: {},
    typeGuard: JobTemplatesResponse,
  }).then((_) => _.recordset);

export const getJobs = (params: JobsRouterRequestData) =>
  createRequest({
    api: "/jobs",
    requestData: params,
    typeGuard: JobsResponse,
  });

export const getBingProductMetaData = (type: "Bing" | "CWC") => {
  if (type === "Bing") {
    return createRequest({
      api: "/getBingMetaData",
      typeGuard: str,
    });
  }
  return createRequest({
    api: "/getCWCMetaData",
    typeGuard: str,
  });
};

export const getPrivacyFilterData = (jobId: string) =>
  createRequest({
    api: `/getPrivacyFilterFileContent?filename=${encodeURIComponent(
      "privacyFilter/" + jobId + "/redacted_output.csv",
    )}`,
    typeGuard: PrivacyFilterTemplateContentArray,
  });

export const getUserManager = () =>
  createRequest({ api: "/getUserManager", typeGuard: GetUserManagerResponse });

export const updateJobPriority = (params: UpdateJobPriorityRequestData) =>
  createRequest({
    api: "/updateJobPriority",
    requestData: params,
    typeGuard: UpdateJobPriorityResponse,
  });

export const validateADOLink = (params: ValidateADOLinkRequestData) =>
  createRequest({
    api: "/validateADOLink",
    requestData: params,
    typeGuard: ValidateADOLinkResponse,
  });

export const createJob = (params: CreateJobRouterRequestData) =>
  createRequest({
    api: "/createjob",
    requestData: params,
    typeGuard: CreatedJobResponse,
  });

export const preSubmitJob = (params: PreSubmitJobRouterRequestData) =>
  createRequest({
    api: "/preSubmitJob",
    requestData: params,
    typeGuard: anyObject,
  });

export const rerunJob = (params: RerunJobRouterParserRequestData) =>
  createRequest({
    api: "/rerunjob",
    requestData: params,
    typeGuard: none,
  });

export const deleteJobs = (params: IDeleteJobParams) =>
  createRequest({
    api: "/deletejobs",
    requestData: params,
    typeGuard: none,
  });

export const cancelJob = (params: CancelJobRouterRequestData) =>
  createRequest({
    api: "/cancelJob",
    requestData: params,
    typeGuard: none,
  });

export const verifyToken = (params: VerifyTokenRouterRequestData) =>
  createRequest({
    api: "/verifyToken",
    requestData: params,
    typeGuard: VerifyTokenResponse,
  });

export const setToken = (params: SetTokenRouterRequestData) =>
  createRequest({
    api: "/setToken",
    requestData: params,
    typeGuard: none,
  });

export const getLoginUrl = () =>
  createRequest({
    api: "/getLoginUrl",
    requestData: {},
    typeGuard: str,
  });

export const updateTokenByCode = (
  params: UpdateRefreshTokenByCodeRouterRequestData,
) =>
  createRequest({
    api: "/updateTokenByCode",
    requestData: params,
    typeGuard: UpdateTokenByCodeResponse,
  });

export const getAriaToken = () =>
  createRequest({
    api: "/getAriaToken",
    requestData: {},
    typeGuard: str,
    dataBag: {
      skipTelemetry: true,
    },
  });

export const deleteToken = (params: IDeleteTokenParams) =>
  createRequest({
    api: "/deleteToken",
    requestData: params,
    typeGuard: none,
  });

export const getDetailsByJobId = (
  params: CreateDetailsByJobIdRouterRequestData,
) =>
  createRequest({
    api: "/detailsByJobId",
    requestData: params,
    typeGuard: array(MetricJudgementRecord),
  });

export const getUtteranceConversationId = (
  params: UtteranceConversationIdRouterRequestData,
) =>
  createRequest({
    api: "/utteranceConversationId",
    requestData: params,
    typeGuard: array(UtteranceConversationId),
    dataBag: {
      jobId: params.JobId,
      expName: params.ExperimentName,
    },
  });

export const getMetricsByJobId = (params: IGetMetricsByJobIdParams) =>
  createRequest({
    api: "/metricsByJobId",
    requestData: params,
    typeGuard: GeneralMetricsResponse,
  });

export const getLMChecklistDetails = (
  params: LMChecklistDetailsByJobIdRouterRequestData,
) =>
  createRequest({
    api: "/lmchecklistDetailsByJobId",
    requestData: params,
    typeGuard: optional(LMChecklistDetailsResponse),
  });

export const getLMChecklistAssertions = (
  params: LMChecklistAssertionsRouterRequestData,
) =>
  createRequest({
    api: "/lmchecklistAssertions",
    requestData: params,
    typeGuard: optional(LMChecklistAssertionsResponse),
  });

export const getLMChecklistIcms = () =>
  createRequest({
    api: "/lmchecklistIcms",
    typeGuard: optional(LMChecklistIcmsResponse),
  });

export const getManagedIdentityToken = (scope: string) =>
  createRequest({
    api: "/getManagedIdentityToken",
    requestData: { Scope: scope },
    typeGuard: anyObject,
  });

export const uploadQuerySet = async (params: IUploadQuerySetParams) =>
  createRequest({
    api: "/uploadQuerySet",
    requestData: (() => {
      const formData = new FormData();
      formData.append("file", params.File);
      formData.append("fileName", params.FileName);
      formData.append("fileType", params.FileType);
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const uploadOPGFolder = async (params: IUploadFolderParams) =>
  createRequest({
    api: "/uploadOPGFolder",
    requestData: (() => {
      const formData = new FormData();
      Array.from(params.folder).forEach((file) => {
        formData.append("files", file);
      });
      formData.append("folderName", params.remoteFolderName);
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

// Add function to create formdata.
export const createFormDataForSkipScrapingFolder = (
  params: IUploadFolderParams,
): FormData => {
  const formData = new FormData();
  Array.from(params.folder).forEach((file) => {
    formData.append("files", file);
    if (file.webkitRelativePath === undefined) {
      formData.append("folderPath", file.name);
    } else {
      const filePath = file.webkitRelativePath.replace(
        params.localFolderName,
        params.remoteFolderName,
      );
      formData.append("folderPath", filePath ?? file.name);
    }
  });
  formData.append("folderName", params.remoteFolderName);
  return formData;
};

export const uploadBizChatSkipScrapingFolder = async (
  params: IUploadFolderParams,
) =>
  createRequest({
    api: "/uploadBizChatSkipScrapingFolder",
    requestData: (() => {
      return createFormDataForSkipScrapingFolder(params);
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const uploadCWCSkipScrapingFolder = async (
  params: IUploadFolderParams,
) =>
  createRequest({
    api: "/uploadCWCSkipScrapingFolder",
    requestData: (() => {
      const formData = new FormData();
      Array.from(params.folder).forEach((file) => {
        formData.append("files", file);
      });
      formData.append("folderName", params.remoteFolderName);
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const uploadSyntheticIngestionDataFolder = async (
  params: IUploadFolderParams,
) =>
  createRequest({
    api: "/uploadSyntheticIngestionDataFolder",
    requestData: (() => {
      const formData = new FormData();
      Array.from(params.folder).forEach((file) => {
        formData.append("files", file);
      });
      formData.append("folderName", params.remoteFolderName);
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const ingestSyntheticData = (params: IIngestSyntheticDataParams) =>
  createRequest({
    api: "/ingestSyntheticData",
    requestData: params,
    typeGuard: str,
  });

export const setConsent = (params: ISetConsentParams) =>
  createRequest({
    api: "/consent/set",
    requestData: (() => {
      return {
        Consent: JSON.stringify(params.Consent),
        User: params.User,
      };
    })(),
    typeGuard: none,
  });

export const getConsent = (params: IGetConsentParams) =>
  createRequest({
    api: "/consent/get",
    requestData: params,
    typeGuard: str,
  }).then((_) => {
    return parseJsonStrWithDefault(_, Consent, { Version: 0 });
  });

export const addJobPermission = (params: AddJobPermissionRequestData) =>
  createRequest({
    api: "/job/permission/add",
    requestData: params,
    typeGuard: none,
  });

export const removeJobPermission = (params: RemoveJobPermissionRequestData) =>
  createRequest({
    api: "/job/permission/remove",
    requestData: params,
    typeGuard: none,
  });

export const getJobPermission = (params: GetJobPermissionRequestData) =>
  createRequest({
    api: "/job/permission/get",
    requestData: params,
    typeGuard: array(str),
  });

export const setIncident = (params: SetIncidentsRouterRequestData) =>
  createRequest({
    api: "/incident/set",
    requestData: params,
    typeGuard: none,
  });

export const getIncident = () =>
  createRequest({
    api: "/incident/get",
    typeGuard: IncidentList,
  });

export const getShareByPassedUsers = () =>
  createRequest({ api: "/shareByPassedUsers/get", typeGuard: array(str) });

export const addShareByPassedUsers = (params: ShareByPassedUsersRequestData) =>
  createRequest({
    api: "/shareByPassedUsers/add",
    requestData: params,
    typeGuard: none,
  });

export const removeShareByPassedUsers = (
  params: ShareByPassedUsersRequestData,
) =>
  createRequest({
    api: "/shareByPassedUsers/remove",
    requestData: params,
    typeGuard: none,
  });

export const setMetricsChangeLog = (params: ISetMetricsChangeParams) =>
  createRequest({
    api: "/metricschange/set",
    requestData: params,
    typeGuard: none,
  });

export const getMetricsChangeLog = () =>
  createRequest({
    api: "/metricschange/get",
    typeGuard: str,
  }).then((_) => {
    return parseJsonStrWithDefault(_, MetricsChangeLogList, undefined);
  });

export const getKeyVaultSecretByKey = (params: IGetKeyVaultSecretByKeyParams) =>
  createRequest({
    api: "/migration/getKeyVaultSecretByKey",
    requestData: params,
    typeGuard: str,
    dataBag: {
      skipSuccessRate: true,
    },
  });

export const sendRevokeConsentNotification = () =>
  createRequest({
    api: "/sendRevokeNotification",
    typeGuard: str,
  });

export const setKeyVaultSecretByKey = (params: ISetKeyVaultSecretByKeyParams) =>
  createRequest({
    api: "/migration/setKeyVaultSecretByKey",
    requestData: params,
    typeGuard: none,
  });

export const uploadConsumerJobJson = (params: IUploadConsumerJobParams) =>
  createRequest({
    api: "/createConsumerJob",
    requestData: params,
    typeGuard: none,
  });

export const getDynamicPermission = (
  params: GetDynamicPermissionRouterRequestData,
) =>
  cleanLocalSubstrateTokenOnError(
    createRequest({
      api: "/me/dynamicpermission",
      requestData: params,
      typeGuard: Permission,
    }),
  );

export const getErrorMessageForFailedJob = (
  params: IGetErrorMessageForFailedJobParams,
) =>
  createRequest({
    api: "/getErrorMessageForFailedJob",
    requestData: params,
    typeGuard: ErrorMessageResponse,
  });

export const getErrorNodeForFailedJob = (
  params: ErrorNodeForFailedJobRouterRequestData,
) =>
  createRequest({
    api: "/getErrorNodeForFailedJob",
    requestData: params,
    typeGuard: ErrorNodeResponse,
  });

export const getScheduledJobs = (param: SearchScheduleJobData) =>
  createRequest({
    api: "/scheduler/list",
    requestData: param,
    typeGuard: ScheduledJobResponse,
  });

export const createScheduledJob = (params: CreateScheduleJobRequestData) =>
  createRequest({
    api: "/scheduler/create",
    requestData: params,
    typeGuard: none,
  });

export const updateScheduledJob = (params: UpdateScheduleJobRequestData) =>
  createRequest({
    api: "/scheduler/update",
    requestData: params,
    typeGuard: none,
  });

export const searchUserById = (params: ISearchUserByIdParams) =>
  cleanLocalSubstrateTokenOnError(
    createRequest({
      api: "/searchUserById",
      requestData: params,
      typeGuard: SearchUserResponse,
    }),
  );

export const getSevalUserList = (
  params: GetDynamicPermissionRouterRequestData,
) =>
  cleanLocalSubstrateTokenOnError(
    createRequest({
      api: "/getSevalUserList",
      requestData: params,
      typeGuard: SearchUserListResponse,
    }),
  );

export const searchUserSelfProfile = () =>
  createRequest({
    api: "/searchUserSelfProfile",
    typeGuard: UserProfileResponse,
  });

export const getJobSBSExp = (params: { JobId: string }) =>
  createRequest({
    api: "/getJobSBSExp",
    requestData: params,
    typeGuard: JobSBSExpResponse,
  });

export const getPerfMetricsQueryResult = (params: { Query: string }) =>
  createRequest({
    api: "/getPerfMetricsQueryResult",
    requestData: params,
    typeGuard: anyObject,
  });

export const getOPGRAIResult = (params: GetOPGRAIResultRequestData) =>
  createRequest({
    api: "/getOPGRAIResult",
    requestData: params,
    typeGuard: OPGRAIResult,
  });

export const getJobPerfMetric = (params: JobPerfMetricRequestData) =>
  createRequest({
    api: "/jobPerfMetric",
    requestData: params,
    typeGuard: KustoQueryResultResponse,
  });

export const getBingClusters = (
  params: GetDynamicPermissionRouterRequestData,
) =>
  createRequest({
    api: "/getBingClusters",
    requestData: params,
    typeGuard: array(str),
  });

export const getSGMembers = (params: GetSGMembersRequestData) =>
  createRequest({
    api: "/getSGMembers",
    requestData: params,
    typeGuard: SearchUserListResponse,
  });

export const searchSGById = (params: SearchSGRouterRequestData) =>
  createRequest({
    api: "/searchSGById",
    requestData: params,
    typeGuard: SubstrateSingleUserInfor,
  });

export const queryMChatMetricsbyMetrics = (
  params: QueryMChatMetricsRouterParserRequestData,
) =>
  createRequest({
    api: "/queryMChatMetrics/byMetrics",
    requestData: params,
    typeGuard: MChatMetricsByMetricsResponse,
  });

export const queryMChatMetricsbyHash = (
  params: QueryMChatAllMetricsRouterParserRequestData,
) =>
  createRequest({
    api: "/queryMChatMetrics/byHash",
    requestData: params,
    typeGuard: MChatMetricsByHashResponse,
  });

export const queryMChatSValue = (params: QuerySvalueRouterRequestData) =>
  createRequest({
    api: "/queryMChatSValue",
    requestData: params,
    typeGuard: MChatSValueResponse,
  });

export const editConfiguration = (params: EditConfigRouterRequestData) =>
  createRequest({
    api: "/configuration/edit",
    requestData: params,
    typeGuard: none,
  });

export const listConfiguration = (
  params: ListConfigSnapshotRouterRequestData,
) =>
  createRequest({
    api: "/configuration/list",
    requestData: params,
    typeGuard: AppConfigurationSnapshotsResponse,
  });

export const getConfiguration = (params: GetConfigSnapshotRouterRequestData) =>
  createRequest({
    api: "/configuration/get",
    requestData: params,
    typeGuard: str,
  });

export const listProducts = () =>
  createRequest({ api: "/product/list", typeGuard: array(Product) });

export const createProduct = (params: ProductCreateRequestData) =>
  createRequest({
    api: "/product/create",
    requestData: params,
    typeGuard: array(Product),
  });

export const deleteProduct = (params: ProductDeleteRequestData) =>
  createRequest({
    api: "/product/delete",
    requestData: params,
    typeGuard: array(Product),
  });

export const updateProduct = (params: ProductUpdateRequestData) =>
  createRequest({
    api: "/product/update",
    requestData: params,
    typeGuard: array(Product),
  });

export const getAvalonResponse = async (params: CallAvalonRequestData) => {
  const basicHeaders = await getBasicHeader();

  if (params.AvalonAccessToken === undefined) {
    const avalonToken = await getAvalonAccessToken().catch(() => undefined);
    params.AvalonAccessToken = avalonToken;
  }

  return cleanLocalAvalonAccessTokenOnError(
    createRequest({
      api: "/callAvalon",
      requestData: params,
      typeGuard: AvalonResponse,
      headers: basicHeaders,
    }),
  );
};

export const getExperiments = (params: ExperimentListRequestData) =>
  createRequest({
    api: "/getNexusPlatformAPI/experimentread/experiment",
    requestData: params,
    typeGuard: ExperimentsResponse,
    retryOptions: { retryCount: 0 },
  });

export const createExperiment = (params: ExperimentRequestData) =>
  createRequest({
    api: `/fetchExpPlatformAPI/feature/create`,
    requestData: params,
    typeGuard: str,
    retryOptions: { retryCount: 0 },
  });

export const getExperimentScorecards = (
  params: ExperimentScorecardListRequestData,
) =>
  createRequest({
    api: `/getNexusPlatformAPI/experimentread/analysisTask`,
    requestData: params,
    typeGuard: ExperimentScorecardsResponse,
    retryOptions: { retryCount: 0 },
  });

export const getScorecardMetrics = (params: ExperimentRequestData) =>
  createRequest({
    api: `/fetchExpPlatformAPI/scorecard/getMetrics`,
    requestData: params,
    typeGuard: ScorecardMetricsResponse,
    retryOptions: { retryCount: 0 },
  });

export const getUpstreamExperiment = (params: FeatureIterationRequestData) =>
  createRequest({
    api: `/featureEvalExperiment/getUpstreamExperiment?shadowABProgressionId=${
      params?.shadowABProgressionId ?? ""
    }`,
    typeGuard: FeatureEvalExperiment,
    retryOptions: { retryCount: 0 },
  });

export const connectIteration = (params: FeatureIterationRequestData) =>
  createRequest({
    api: `/featureEvalExperiment/iteration/connect`,
    requestData: params,
    typeGuard: num,
    retryOptions: { retryCount: 0 },
  });

export const reportLog = async (params: LogRequestData) => {
  return createRequest({
    api: "/log",
    requestData: params,
    typeGuard: none,
    headers: await getBasicHeaderWithoutAuth(), // override the default header to avoid get api token
    dataBag: {
      skipTelemetry: true,
    },
  });
};

export const getGPTList = (params: GPTListRouterParser) =>
  createRequest({
    api: "/getGPTList",
    requestData: params,
    typeGuard: GPTAgentListResponse,
  });

export const validateQuerySetAgent = async (file: File) =>
  createRequest({
    api: "/checkLocalQuerySetWarning",
    requestData: (() => {
      const formData = new FormData();
      formData.append("file", file);
      return formData;
    })(),
    typeGuard: optional(str),
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const checkOnlineQuerySetWarning = (
  param: CheckOnlineQuerySetWarningRouterRequestData,
) =>
  createRequest({
    api: `/checkOnlineQuerySetWarning`,
    requestData: param,
    typeGuard: optional(str),
  });

export const getSyntheticVirtualTenants = () =>
  createRequest({
    api: "/getSyntheticVirtualTenants",
    typeGuard: array(VirtualTenant),
    method: "GET",
  });

export const getSyntheticQuerySets = () =>
  createRequest({
    api: "/getSyntheticQuerySets",
    typeGuard: array(str),
    method: "GET",
  });
